.Segments{
    
    margin-top: 100px;
    width: 100%;
    
}
.Columns{
    
    display: grid;
}

.Seg2{
    display: flex;
    flex-direction: column;
    justify-content:space-around ;
    align-items: flex-start;
    background-color:rgb(219, 218, 217) ;
    aspect-ratio: 1;
    
    box-sizing: border-box;
    padding: 1rem;
}
.Seg2 div{
    margin: 0;
    padding: 0;
    background-color: inherit;
}
.Seg2 h5{
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    background-color: inherit;
}
.Seg2 h2{
    margin: 0;
    padding: 0;
    font-family: 'Moirai One';
    font-weight: bold;
    font-size:4rem;
    background-color: inherit;
}
.Seg2 p{
    margin: 0;
    padding: 0;
    background-color: inherit;
}
.centre{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.Seg1{
    height: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    aspect-ratio: 1;
}
.Seg1 img{
    width: fit-content;
    height: 100%;
}
.VMbutton{
    display: flex;
    justify-content: center;
    align-items: center;
}
.VMbutton p{
    font-size: 2rem;
    color:rgb(33, 24, 56);
    text-decoration:none;
}
.VMbutton a{
    border: none;
    background: linear-gradient(to right, rgb(223, 181, 101) 50%, rgb(219, 218, 217) 50%);    background-size: 200% 100%;
    background-position: bottom right;
    transition: all .7s ease-out;
    text-decoration:none;
}
.VMbutton a:hover{
    background-position: bottom left;
    cursor: pointer;
    
}
.fadeIn{
    animation:3s both  fadeIn;
}
@media screen and (min-width: 1400px) {
    .Segments{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
   
    .Columns{
        display: grid;
        grid-template-columns: 650px 650px;
        grid-template-rows: 650px 650px 650px;
        
    }
    #topl{
        order:1;
    }
    #topr{
        order:2;
    }
    #midl{
        order:3;
    }
    #midr{
        order:4;
    }
    #botl{
        order:5;
    }
    #botr{
        order:6;
    }
    .Seg2 h2{
        font-size: 5rem;
    }
    .Seg2 h5{
        font-size: 1.7rem;
    }
    .Seg2 p{
        font-size: 1.5rem;
    }
}
@media screen and (min-width: 1000px) and (max-width:1400px) {
    .Segments{
        display: flex;
        align-items: center;
        justify-content: center;
        
    }
    .Columns{
        grid-template-columns: 40vw 40vw;
        grid-template-rows: 40vw 40vw 40vw;
    }
    .Seg2 h2{
        font-size: 5vw;
    }
    .Seg2 h5{
        font-size: 2vw;
    }
    .Seg2 p{
        font-size: 1.5vw;
    }
    #topl{
        order:1;
    }
    #topr{
        order:2;
    }
    #midl{
        order:3;
    }
    #midr{
        order:4;
    }
    #botl{
        order:5;
    }
    #botr{
        order:6;
    }
}
@media screen and (max-width: 1000px)  {
    .Segments{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Columns{
        grid-template-columns: 70vw;
        grid-template-rows: 70vw 70vw 70vw 70vw 70vw 70vw;
        text-overflow: clip;
    }
    
    .Seg2 h2{
        font-size: 7vw;
        text-overflow: clip;
    }
    .Seg2 h5{
        font-size: 3vw;
    }
    .Seg2 p{
        font-size: 2.5vw;
    }
}
@media screen and (max-width: 600px) {
    .Segments{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Columns{
        grid-template-columns: 80vw;
        grid-template-rows: 80vw 80vw 80vw 80vw 80vw 80vw;
        text-overflow: clip;
    }
    
    .Seg2 h2{
        font-size: 10vw;
        text-overflow: clip;
    }
    .Seg2 h5{
        font-size: 4vw;
    }
    .Seg2 p{
        font-size: 3vw;
    }
    
}

@keyframes transitionHorizontal{
    from{background-position: left;}
    to{background-position: right ;}
}
@keyframes fadeIn{
    from {
        transform: translateY(50px);
        opacity: 0;
      }
      to {
        transform: translateY(0);
        opacity: 1;
      }
}