@font-face {
    font-family: 'Sofia';
    src: url('../App/SofiaSans-Regular.ttf');
  }
  @import url('https://fonts.googleapis.com/css2?family=Moirai+One&display=swap');
*{
    font-family: 'Sofia', sans-serif;
    font-weight: 400; 
    font-style: normal;
    background-color: rgb(213, 210, 206);
    

}
::selection{
    background: rgb(223, 181, 101);
}
html,body{
    width: 100%;
    scroll-behavior: smooth;
    
    
}

