.Projects{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Projects h2{
    font-size: 4rem;
    color: rgb(33, 24, 56);
    font-family: 'Moirai One';
    font-weight: bold;
}
.Projects h3{
    font-size: 1.5rem;
}
.Projects h4{
    font-size: 1.2rem;
}
.Projects p{
    font-size: 1rem;
}
.Header{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 150px;
}
.Header h1{
    font-family: 'Alegreya';
    font-weight: bold;
    color: rgb(33, 24, 56);
    font-size:4rem;
    font-style: italic;
    background-color: inherit;
}
.RecentP{
    display: flex;
    flex-direction: column;
    
}
.UniP{
    display: flex;
    flex-direction: column;
}
#Banner{
   margin-top: 100px;
   margin-bottom: 100px;
}
#Banner img{
    width:85vw;
    height: 200px;
    object-fit: cover;
    object-position: 50% 0%;
    opacity: 70%;
    border: none;
    border-radius: 5px;
}
.ProjectList{
    width: 80vw;
}
.recentProj{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    column-gap: 40px;
}
.projPc img{
    width: 35vw;
    height: auto;
}
.projMobile img{
    width:15vw;
    height: auto;
}
.projDesc{
   
}
#UniP1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 50px;
}
.BeforeAfter{
    display: flex;
    align-items: center;
    column-gap: 40px;
}
.AppSS{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AppSS img{
    width:200px;
    height: auto;
}
#UniP2{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 100px;
}
#GameDemo video{
    width: 600px;
    height: auto;
}
#UniP3{
    display: flex;
    justify-content: space-around;
    align-items: center;
    column-gap: 50px;
}
.PaintSS img{
    width:300px;
    height: auto;
}
.bigdivider{
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    border-bottom: 6px rgb(33, 24, 56) solid ;
}
.divider{
    margin-top: 40px;
    margin-bottom: 40px;
    width: 75%;
    align-self: center;
    border-bottom: 2px rgb(33, 24, 56) solid ;
}

@media screen and (min-width: 2000px) {
    .ProjectList{
        width: 65vw;
    }
    .projPc img{
        width: 25vw;
        height: auto;
    }
    .projMobile img{
        width: 13vw;
        height: auto;
    }
}
@media screen and (max-width: 1200px) {
    .AppSS img{
        width:20vw;
    }
    #GameDemo video{
        width: 50vw;
    }
    .PaintSS img{
        width: 30vw;
    }
    #UniP1{
        flex-direction: column;
        row-gap: 50px;
    }
    #UniP2{
        flex-direction: column-reverse;
        row-gap: 50px;
    }
    #UniP3{
        flex-direction: column;
        row-gap: 50px;
    }
    .Projects h2{
        font-size: 3.5rem;
    }
}
@media screen and (max-width: 900px) {
    .recentProj{
        flex-direction: column;
        row-gap: 50px;
    }
    .projPc img{
        width: 65vw;
    }
    .projMobile img{
        width: 45vw;
    }
}