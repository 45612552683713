.About{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    height: auto;
    
    margin-left: 10vw;
    margin-right: 10vw;
    
}
#topBox{
    margin: 0;
    width: 100%;
    height: 40px;
    background-color: rgb(223, 181, 101);
    align-self: flex-start;
    border-radius:10px;
    margin-bottom: 100px;
    
}
.topper{
    align-self:flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    
    column-gap: 20px;
}

.box{
    width: 1rem;
    height:1rem;
    background-color: rgb(223, 181, 101); 
}
.boxText{
    color:rgb(33, 24, 56);
    font-size: 1.2rem;

}
.paragraph{
    
    width: 100%;
}
.paragraph h2{
    font-family: 'Moirai One';
    font-weight: bold;
    font-size: 5rem;
}
.paragraph p{
    font-size: 1.5rem;
    
}
#highlight{
    font-size: 1.7rem;
    background-color:rgb(223, 181, 101);
    border-radius: 3px;
}
@media screen and (max-width: 600px) {
    
    .paragraph h2{
        font-size: 12vw;
    }
    .paragraph p{
        font-size: 5vw;
    }
    #highlight{
        font-size: 6vw;
    }
}
