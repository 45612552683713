.Hero{
    width:100vw; 
    height:700px;
    display: flex;
    flex-direction: column;
    
    align-items: center;
    margin-bottom: 300px;
}
.Name{
    display: flex;
    align-items: top;
    justify-content: space-around;
    column-gap: 10vw;
    margin-top: 15rem;
    margin-left: 10vw;
    margin-right: 10vw;
}
.Name h1{
    color:rgb(33, 24, 56);
    font-size:5rem;
    
}
.Name h2{
    color:rgb(33, 24, 56);
    font-size:1.5rem;
    opacity: 80%;
    text-indent: 2vw;
    
}
#profile{
    width: 300px;
    height: auto;
    border-radius: 100%;
    border: 1vw double rgb(147, 95, 60) ;
    align-self: center;
}
@media screen and (max-width: 850px) {
    
    .Name{
        flex-direction: column;
        margin-top: 5rem;
    }
}