.Navbar{
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 30px;
    
    
}
#navigation{
    align-self: top;
    display: flex;
    justify-content: space-evenly;
    
    column-gap: 2vw;
    color: rgb(33, 24, 56);
    margin-right: 10vw;
    
}
#logo{
    width: 250px;
    height: 100%;
    margin-left: 10vw;
    margin-top: 20px;
    
}
.Navbar a{
    color:rgb(33, 24, 56);
    text-decoration: none;
}
#navigation a{
    padding: 10px;
    margin-top: 10px;
    background: linear-gradient(to bottom, rgb(223, 181, 101) 50%, rgb(213, 210, 206) 50%);    
    background-size: 100% 200%;
    background-position: bottom right;
    transition: all .5s ease-out;
}
#navigation h2{
    background: none;
}
#navigation a:hover{
 background-position:  top right;
 color: rgb(70, 45, 28);
}

@media screen and (min-width: 700px) {
    #Menu {
        display: none;
    }
}
@media screen and (max-width: 700px) {
    #navigation{
        display: none;
    }
    
    .Navbar{
        justify-content: center;
        margin-top: 10px;
    }
    #logo{
        align-self: center;
        width: 250px;
        margin-top: 20px;
        margin-left: 0;
    }
}