#menuNav{
    position: absolute;
    right:-5px;
    top: 50px;
    padding: 30px;
    padding-left: 50px ;
    border-bottom-left-radius: 10px;
    flex-direction: column;
    align-items: right;
    justify-content: right;
    text-align: right;
    border: white solid 3px;
    margin-top: 20px;
    background-color:rgba(0,0,0,0.1) ;
    backdrop-filter: blur(20px);
}

#menu{
    position: absolute;
    top:30px;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: right;

}
#ham{
    width:40px;

}
#menuNav a h2{
    background:none;
    color: rgb(33, 24, 56);
    text-decoration: none;
}
#menuNav a{
    background:none;
    text-decoration: none;
}

