#Contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 200px;
    row-gap: 30px;
}
#Contact div{
    width: 80vw;
}
#socials{
    display: flex;
    justify-content: center;
    column-gap: 50px;
    
}
#socials a{
    color: purple;
}
@media screen and (max-width: 700px) {
    #socials{
        flex-direction: column;
    }
}

